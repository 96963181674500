/*
These functions make sure WordPress
and Foundation play nice together.
*/
  
jQuery(document).ready(function($) {

	//add comma to thousands
	function numberWithCommas(x){
	var parts = x.toString().split(".");
	parts[0] = parts[0].replace(/,/g,"");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return parts.join(".");
	}
	
	//check if field is number only
	function isNumber(evt) {
		evt = (evt) ? evt : window.event;
		var charCode = (evt.which) ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
	// Remove empty P tags created by WP inside of Accordion and Orbit
	jQuery('.accordion p:empty, .orbit p:empty').remove();

	//Adds Flex Video to YouTube and Vimeo Embeds
	jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
		if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
		  jQuery(this).wrap("<div class='widescreen responsive-embed'/>");
		} else {
		  jQuery(this).wrap("<div class='responsive-embed'/>");
		}
	});

	jQuery(".numbers-with-commas-only input").keypress(function(event){
 
		/*
		evt = (evt) ? evt : window.event;
		var charCode = (evt.which) ? evt.which : evt.keyCode;

		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
		*/

		//jQuery(this).val($(this).val().replace(/[^\d].+/, ""));
		if (event.which > 31 && (event.which < 48 || event.which > 57)) {
			event.preventDefault();
		}
	});

	jQuery('input.txnumber, .numbers-with-commas-only input').keyup(function(event) {

		// skip for arrow keys
		if(event.which >= 37 && event.which <= 40) return;
		// format number
		jQuery(this).val(function(index, value) {
		   return numberWithCommas(value);
		});

	});

}); 